<template>
    <b-modal id="updateSubscriptionModel" size="sm" title="Update Company's subscription" @hidden="reset">
        <form @submit.prevent="updateCompanySubscription" @reset.prevent="closeModal">
            <div class="row">
                <div class="col-md-12" v-if="company">
                    <div class="form-group">
                        <label>Product</label>
                        <b-form-select placeholder="Select Product" v-model="form.product"
                                       :options="['Basic', 'Standard', 'Premium']"/>
                    </div>

                    <div class="form-group">
                        <label>Plan</label>
                        <b-form-select placeholder="Select Plan" v-model="form.plan" :options="['Monthly', 'Yearly']"/>
                    </div>

                    <div class="form-group" v-if="form.product === 'Premium'">
                        <label>Amount</label>
                        <b-form-input type="number" v-model="form.amount" placeholder="Enter Amount"/>
                    </div>
                </div>
                <div slot="modal-footer" class="col-md-12 text-center">
                    <button type="submit" class="btn btn-primary m-2">Update</button>
                    <button type="reset" class="btn btn-secondary m-2">Cancel</button>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
export default {
  name: "CompanySubscriptionUpdate",
  data() {
    return {
      form: {
        product: "Basic",
        plan: "Monthly",
        amount: 0
      },
      company: null
    };
  },
  mounted() {
    this.$root.$on("selected_company", company => {
      this.company = company;
    });
  },
  methods: {
    updateCompanySubscription() {
      let data = {
        ...this.form,
        company_id: this.company.hash_id
      };
      this.$store.commit("toggle_loader", true);
      this.$store
        .dispatch("updateCompanySubscription", data)
        .then(response => {
          this.$store.commit("toggle_loader", false);
          this.$parent.getDashboardData();
          if (!response.data.error) alert(response.data.error_msg);
          this.closeModal();
        })
        .catch(error => {
          this.$store.commit("toggle_loader", false);
          if (error.response.status === 422 && error.response.data.message)
            alert(
              error.response.data.message +
                "\n" +
                Object.values(error.response.data.errors)[0][0]
            );
        });
    },
    closeModal() {
      this.$root.$emit("bv::hide::modal", "updateSubscriptionModel");
    },
    reset() {
      this.form = {
        product: "Basic",
        plan: "Monthly",
        amount: 0
      };
    }
  }
};
</script>

<style scoped>
.alignment {
  left: 5%;
  padding-top: 7px;
}
</style>
